import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { unregister } from './registerServiceWorker';
import configureStore from './store';

import './polyfill';

const { store, persistor } = configureStore();

// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';

unregister();

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, ArcElement);

import ReactGA from 'react-ga4';
import { SettingConstant } from 'constants/settings';
ReactGA.initialize(SettingConstant.GA_MEASUREMENT_ID || 'G-PBT96QFNZ9');
ReactGA.send('pageview');

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
// disable ServiceWorker
// registerServiceWorker();
