import moment from 'moment';

export function shouldDisableAddingData(isSuperUser, activeCompany, userRole) {
  return (
    (isSuperUser && activeCompany === 'Internal User') ||
    (activeCompany === 'Internal User' && userRole !== 'Internal User')
  );
}

export function disabledAlpha(e) {
  if (!/[0-9]/g.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
    e.preventDefault();
  }
}

export function dateValueFormat(e) {
  if (/^[0-9]+$/.test(e.key)) {
    if (e.target.value.length === 4) {
      e.target.value += '-';
    }
    if (e.target.value.length === 7) {
      e.target.value += '-';
    }
  }
}

export function disabledAlphaForDate(e) {
  if (!/[0-9]/g.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
    e.preventDefault();
  }
}

export function generateDate(dateValue) {
  if (!dateValue) return dateValue;

  if (moment(dateValue).format('YYYY-MM-DD') === 'Invalid date') {
    const splitDate = dateValue.split('');
    if (/[0-9]/g.test(splitDate[0] + splitDate[1])) {
      return moment(dateValue, 'DDMMYYYY').format('YYYY-MM-DD');
    }
  }

  return dateValue;
}

export function hasPermission(userPermissions, moduleName, functionName) {
  let permissionName = `Express${functionName == 'CompanySelect' ? functionName : 'AllAccess'}`.toLowerCase();
  return userPermissions.find((x) => x.Permission_Name.toLowerCase() == permissionName) !== undefined;
}

export function transformObjectKeys(input, toLowerCase = true) {
  if (typeof input !== 'object') return input;
  if (Array.isArray(input)) return input.map((x) => transformObjectKeys(x, toLowerCase));
  return Object.keys(input).reduce(function (newObj, key) {
    let val = input[key];
    let newVal = typeof val === 'object' && val !== null ? transformObjectKeys(val, toLowerCase) : val;
    newObj[toLowerCase ? key.toLowerCase() : key.toUpperCase()] = newVal;
    return newObj;
  }, {});
}
