import React from 'react';

function MaintenanceScreen() {
  return (
    <center style={{ padding: 150, fontSize: 20, color: '#333' }}>
      <h1>We&rsquo;ll be back soon!</h1>
      <div>
        <p>
          Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to you can
          always contact us on 1300 735 133 and select option number 2, otherwise we&rsquo;ll be back online shortly!
        </p>
        <p>&mdash; The eStore Logistics Team</p>
      </div>
    </center>
  );
}

export default MaintenanceScreen;
