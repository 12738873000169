export function sortObject(a, b, key) {
  // eslint-disable-next-line no-nested-ternary
  return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
}

export function sortData(data, direction, sortBy) {
  return data.sort((a, b) => {
    let val = null;
    if (a[sortBy] !== undefined && b[sortBy] !== undefined) {
      if (direction === 'down') {
        if (b[sortBy] === null) {
          val = 1;
        } else if (a[sortBy] === null) {
          val = -1;
        } else if (/^[+-]?\d+(\.\d+)?$/.test(a[sortBy]) && /^[+-]?\d+(\.\d+)?$/.test(b[sortBy])) {
          val = parseFloat(b[sortBy], 10) - parseFloat(a[sortBy], 10);
        } else {
          val = b[sortBy] ? b[sortBy].localeCompare(a[sortBy]) : 0;
        }
      } else if (a[sortBy] === null) {
        val = 1;
      } else if (b[sortBy] === null) {
        val = -1;
      } else if (/^[+-]?\d+(\.\d+)?$/.test(a[sortBy]) && /^[+-]?\d+(\.\d+)?$/.test(b[sortBy])) {
        val = parseFloat(a[sortBy], 10) - parseFloat(b[sortBy], 10);
      } else {
        val = a[sortBy] ? a[sortBy].localeCompare(b[sortBy]) : 0;
      }
    }

    return val;
  });
}
