import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

import CompanyReducer from './Company/reducer';
import FilterReducer from './Filter/reducer';
import ProductReducer from './Product/reducer';
import UserReducer from './User/reducer';

// const filterPersistConfig = {
//   key: 'express-estore-logistics:filter',
//   storage,
// };

const rootReducer = combineReducers({
  asideShow: { value: false },
  darkMode: { value: false },
  company: CompanyReducer,
  filter: FilterReducer, // persistReducer(filterPersistConfig, FilterReducer),
  product: ProductReducer,
  user: UserReducer,
});

export default rootReducer;
