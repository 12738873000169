import Cookies from 'js-cookie';

/**
 * setCookie  - Sets a cookie in the user's browser
 * @param {String} name     Name/key of cookie to save
 * @param {String|Object} value    Value to save in cookie
 * @param {Object} options  Options to override defaults
 */
export const setCookie = (name, value, options = {}) =>
  Cookies.set(name, value, {
    secure: process?.env.NODE_ENV === 'production',

    ...options,
  });

/**
 * getCookie  - Retrieves a cookie. Not super necessary, but it
 *              keeps things uniform
 * @param {String} name Name of cookie to get
 *
 * @returns {String|Object}
 */
export const getCookie = (name) => Cookies.get(name);

/**
 * deleteCookie  - Removes a cookie. Not super necessary, but it
 *                 keeps things uniform
 * @param {String} name Name of cookie to get
 */
export const deleteCookie = (name) => Cookies.remove(name);
