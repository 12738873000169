import * as constants from './constants';

import * as endPoint from '../../constants/endpoint';
import { APIHelper } from '../../helpers';

export const setIsLoading = (isLoading) => {
  return {
    type: constants.PRODUCT_IS_LOADING,
    payload: isLoading,
  };
};

export const setIsError = (isError) => {
  return {
    type: constants.PRODUCT_IS_ERROR,
    payload: isError,
  };
};

export const setErrorMessage = (message) => {
  return {
    type: constants.PRODUCT_SET_ERROR_MESSAGE,
    payload: message,
  };
};

export const setOnFetchingData = () => {
  return {
    type: constants.PRODUCT_IS_FETCHING_DATA,
  };
};

export const setProductData = (data) => {
  return {
    type: constants.PRODUCT_SET_DATA,
    payload: data,
  };
};

export const getAllProducts = () => {
  return async (dispatch) => {
    dispatch(setOnFetchingData());
    try {
      const response = await APIHelper.getRequest(endPoint.product.list);
      const prodResource = response.resource;
      if (prodResource) {
        //const productList = prodResource.map((prod) => prod.item);
        dispatch(setProductData(prodResource));
      } else {
        dispatch(setIsError(true));
        dispatch(setErrorMessage('There is no response.'));
      }
    } catch (error) {
      dispatch(setProductData([]));
      dispatch(setIsError(true));
      dispatch(setErrorMessage(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
