import * as global from '../constants/global';
import * as Cookies from './cookie';
import * as Storage from './storage';

const SESSION_NAME = '_estore-logistics-session';
const { storageName } = global;

const Session = {
  isAuthenticated() {
    return !!Cookies.getCookie(SESSION_NAME);
  },
  authenticate(user) {
    Cookies.setCookie(SESSION_NAME, user, { expires: 1 });
  },
  getUserSession() {
    if (Cookies.getCookie(SESSION_NAME)) {
      const userSession = JSON.parse(Cookies.getCookie(SESSION_NAME));
      userSession.activeCompany = Storage.getStorage(storageName.activeCompany);

      return userSession;
    }

    return null;
  },
  signOut() {
    Cookies.deleteCookie(SESSION_NAME);
  },
};

export default Session;
