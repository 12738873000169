/* eslint-disable no-async-promise-executor */
import { httpService } from './httpService';
import { refreshToken } from './externalLoginHelper';

function handleHttpError(error) {
  const serverErrorCode = [400, 403, 404, 422];
  const genericErrorMessage = 'Failed to process your request.';

  if (error.response && error.response.data) {
    let responseData = null;
    if (serverErrorCode.includes(error.response.status)) {
      responseData = error.response.data.error.message;
    } else if (error.response.status === 401) {
      responseData = 'Unauthorized';
    } else if (error.response.data.error) {
      responseData = error.response.data.error.message;
    } else {
      responseData = error.response.data;
    }

    return responseData || genericErrorMessage;
  }

  return genericErrorMessage;
}

const makeHttpRequest = async (apiCall, attempts = 1) => {
  try {
    const data = await apiCall();
    if (data === 401 || data === 403) {
      if (attempts === 1) {
        await refreshToken();
        return await makeHttpRequest(apiCall, attempts + 1);
      } else {
        return Promise.reject('Unauthorized');
      }
    }

    if (!data || data.MESSAGE || data.error) {
      return Promise.reject(data?.MESSAGE || data?.error?.message || 'Failed to process your request.');
    } else {
      return Promise.resolve(data && Array.isArray(data) && data.length > 0 && data[0].STATUS_CODE ? data[0] : data);
    }
  } catch (e) {
    console.log('catch', e);
    return Promise.reject(handleHttpError(e));
  }
};

export function getRequest(path, options = {}) {
  return makeHttpRequest(() =>
    httpService.get(path + (options.params ? `?${new URLSearchParams(options.params).toString()}` : '')),
  );
}

export function postRequest(path, data, options = {}) {
  return makeHttpRequest(() => httpService.post(path, data));
}

export function putRequest(path, data, options = {}) {
  return makeHttpRequest(() => httpService.put(path, data));
}

export function patchRequest(path, data, options = {}) {
  return makeHttpRequest(() => httpService.patch(path, data));
}

export function deleteRequest(path, options = {}) {
  return makeHttpRequest(() =>
    httpService.delete(path + (options.params ? `?${new URLSearchParams(options.params).toString()}` : '')),
  );
}
