/**
 * setStorage  - Sets a storage in the user's browser
 * @param {String} name     Name/key of storage to save
 * @param {String|Object} value    Value to save in storage
 * @param {Object} options  Options to override defaults
 */
export const setStorage = (name, value) => {
  try {
    localStorage.setItem(name, value);
  } catch (err) {
    console.log(`Trying to set ${name} with value ${value}`, err);
  }
};

/**
 * getStorage  - Retrieves a storage. Not super necessary, but it
 *              keeps things uniform
 * @param {String} name Name of storage to get
 *
 * @returns {String|Object}
 */
export const getStorage = (name) => localStorage.getItem(name);

/**
 * deleteStorage  - Removes a storage. Not super necessary, but it
 *                 keeps things uniform
 * @param {String} name Name of storage to get
 */
export const deleteStorage = (name) => localStorage.removeItem(name);
