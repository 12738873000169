import * as constants from './constants';

import * as endPoint from '../../constants/endpoint';
import { APIHelper } from '../../helpers';

export const setIsLoading = (isLoading) => {
  return {
    type: constants.COMPANY_IS_LOADING,
    payload: isLoading,
  };
};

export const setIsError = (isError) => {
  return {
    type: constants.COMPANY_IS_ERROR,
    payload: isError,
  };
};

export const setErrorMessage = (message) => {
  return {
    type: constants.COMPANY_SET_ERROR_MESSAGE,
    payload: message,
  };
};

export const setOnFetchingData = () => {
  return {
    type: constants.COMPANY_IS_FETCHING_DATA,
  };
};

export const setCompanyData = (data) => {
  return {
    type: constants.COMPANY_SET_DATA,
    payload: data,
  };
};

export const getAllCompanies = () => {
  return async (dispatch) => {
    dispatch(setOnFetchingData());
    try {
      const options = { params: { activeonly: 'Y' } };
      const response = await APIHelper.getRequest(endPoint.company.list, options);

      if (!response.STATUS_CODE) {
        dispatch(setCompanyData(response.resource));
      } else {
        dispatch(setIsError(true));
        dispatch(setErrorMessage(response.MESSAGE));
      }
    } catch (error) {
      dispatch(setCompanyData([]));
      dispatch(setIsError(true));
      dispatch(setErrorMessage(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
