import Dexie from 'dexie';

const dbName = 'ImportedData';
function openDB() {
  //set the database
  const db = new Dexie(dbName);
  //create the database store
  db.version(1).stores({
    data: 'id, data, columns, reviewValid, payload',
  });
  db.open().catch((err) => {
    console.log(err.stack || err);
  });
  return db;
}

export async function getData() {
  const db = openDB();
  return await db.data.toArray();
}

export async function addData(item) {
  const db = openDB();
  const allData = await db.data.toArray();
  if (allData && allData[0]) {
    db.data.delete(1);
  }

  db.data.add(item);
}
