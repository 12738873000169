import * as constants from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  profile: null,
  isSuperUser: false,
  activeCompany: '',
  activeCompanyLabel: '',
  userPermissions: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.USER_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case constants.USER_IS_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case constants.USER_SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case constants.USER_IS_FETCHING_DATA:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: '',
        profile: null,
        isSuperUser: false,
        activeCompany: '',
      };
    case constants.USER_SET_PROFILE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: '',
        profile: action.payload,
      };
    case constants.USER_SET_ACTIVE_COMPANY:
      return {
        ...state,
        activeCompany: action.payload,
      };
    case constants.USER_IS_SUPER_USER:
      return {
        ...state,
        isSuperUser: action.payload,
      };
    case constants.USER_SET_ACTIVE_COMPANY_LABEL:
      return {
        ...state,
        activeCompanyLabel: action.payload,
      };
    case constants.USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload || [],
      };
    default:
      return state;
  }
};

export default reducer;
