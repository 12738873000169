/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Authentication from '../auth/Authentication';

class ProtectedRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { component: Component, ...others } = this.props;

    const renderRoute = (props) => {
      if (Authentication.isAuthenticated()) {
        // Authentication.renewExpiredDate();
        return <Component {...props} />;
      }

      return <Redirect to={{ pathname: '/', state: { returnUrl: props.location } }} />;
    };

    return <Route {...others} render={renderRoute} />;
  }
}

ProtectedRoute.propTypes = { component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]) };
ProtectedRoute.defaultProps = { component: null };

export default ProtectedRoute;
