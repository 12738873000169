import * as constants from './constants';

import * as endPoint from '../../constants/endpoint';
import * as global from '../../constants/global';
import { APIHelper, StorageHelper } from '../../helpers';

import { setCompanyData, getAllCompanies } from '../Company/actions';
import { getAllFilterCriteria, clearAllFilter } from '../Filter/actions';
import { setProductData, getAllProducts } from '../Product/actions';
import Authentication from 'auth/Authentication';

const { storageName } = global;

export const setIsLoading = (isLoading) => {
  return {
    type: constants.USER_IS_LOADING,
    payload: isLoading,
  };
};

export const setIsError = (isError) => {
  return {
    type: constants.USER_IS_ERROR,
    payload: isError,
  };
};

export const setErrorMessage = (message) => {
  return {
    type: constants.USER_SET_ERROR_MESSAGE,
    payload: message,
  };
};

export const setOnFetchingData = () => {
  return {
    type: constants.USER_IS_FETCHING_DATA,
  };
};

export const setUserProfile = (data) => {
  return {
    type: constants.USER_SET_PROFILE,
    payload: data,
  };
};

export const setActiveCompany = (company) => {
  return (dispatch) => {
    dispatch({ type: constants.USER_SET_ACTIVE_COMPANY, payload: company });
    StorageHelper.setStorage(storageName.activeCompany, company);
  };
};

export const setIsSuperUser = (isSuperUser) => {
  return {
    type: constants.USER_IS_SUPER_USER,
    payload: isSuperUser,
  };
};

export const setActiveCompanyLabel = (companyLabel) => {
  return (dispatch) => {
    dispatch({ type: constants.USER_SET_ACTIVE_COMPANY_LABEL, payload: companyLabel });
  };
};

export const setUserPermissions = (userPermissions) => {
  return (dispatch) => {
    dispatch({ type: constants.USER_PERMISSIONS, payload: userPermissions });
  };
};

export const afterLogin = () => {
  return async (dispatch) => {
    dispatch(setOnFetchingData());
    try {
      //const response = await APIHelper.getRequest(endPoint.user.get);

      /*if (response) */ {
        //delete response.session_token;
        //delete response.session_id;

        const response = Authentication.getUser();
        dispatch(setUserProfile(response));
        dispatch(setActiveCompany(response.company));

        if (response.company === 'Internal User' || response.role === 'Internal User') {
          dispatch(setIsSuperUser(true));
          await dispatch(getAllCompanies());
        } else {
          await dispatch(getAllProducts());
          await dispatch(getAllFilterCriteria());
        }
      }
    } catch (error) {
      dispatch(setIsError(true));
      dispatch(setErrorMessage(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const getUserPermissions = () => {
  return async (dispatch) => {
    const response = Authentication.getUser();
    const res = await APIHelper.getRequest(
      `${endPoint.user.getUserPermissions}?UserName=${encodeURIComponent(response.email)}&ResourceID=1`,
    );
    dispatch(setUserPermissions(res));
  };
};

export const afterLogout = () => {
  return (dispatch) => {
    dispatch(setUserProfile(null));
    dispatch(setIsSuperUser(false));
    dispatch(setActiveCompany(''));
    dispatch(setActiveCompanyLabel(''));
    dispatch(setCompanyData([]));
    dispatch(setProductData([]));
    dispatch(clearAllFilter([]));
  };
};
