import { getAllTokenData } from './tokenHelper';
import { SettingConstant } from '../constants/settings';
import { StorageHelper } from '../helpers';
import { storageName } from '../constants/global';

class HttpService {
  buildConfig(configCustom, method, data) {
    try {
      const headers = {
        'Content-Type': 'application/json; charset=utf-8',
        'Ocp-Apim-Subscription-Key': SettingConstant.SUBCRIPTION_KEY,
      };

      const tokenData = getAllTokenData();
      const token = tokenData ? tokenData.access_token : '';

      if (token) {
        headers['Authorization'] = `Bearer ${token}`;

        const company = StorageHelper.getStorage(storageName.activeCompany);
        if (company) {
          headers['Company'] = company;
          headers['X-Company-Code'] = company;
        }
      }

      const config = {
        method: method || 'get',
        headers: headers,
      };

      if (data) {
        config['body'] = JSON.stringify(data);
      }

      return configCustom ? { ...config, configCustom } : config;
    } catch (error) {
      throw new Error(error);
    }
  }

  async handleResult(result) {
    if (result.status == 401 || result.status == 403) {
      //this.forceAuthentication();
      return result.status;
    }

    try {
      const res = await result.json();
      if (res.statusCode == 404) return null;
      return res;
    } catch (e) {
      if (result.status == 200) return { STATUS_CODE: 2040 };
      throw e;
    }
  }

  async get(url, config) {
    try {
      const results = await fetch(url, this.buildConfig(config, 'get'));
      return await this.handleResult(results);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async post(url, data, config) {
    try {
      const results = await fetch(url, this.buildConfig(config, 'post', data));
      return await this.handleResult(results);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async put(url, data, config) {
    try {
      const results = await fetch(url, this.buildConfig(config, 'put', data));
      return await this.handleResult(results);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async patch(url, data, config) {
    try {
      const results = await fetch(url, this.buildConfig(config, 'PATCH', data));
      return await this.handleResult(results);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async delete(url, config) {
    try {
      const results = await fetch(url, this.buildConfig(config, 'delete'));
      return await this.handleResult(results);
    } catch (error) {
      return this.handleError(error);
    }
  }

  forceAuthentication() {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('unauthorized', 'true');
    window.location.search = urlParams;
  }

  handleError(error) {
    console.log(error);

    this.forceAuthentication();

    return Promise.resolve({ success: false, error: error });
  }
}
const httpService = new HttpService();
export { httpService };
