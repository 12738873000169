import moment from 'moment';

export function dateTimeFormat(date) {
  let result;

  if (date) {
    const localDate = new Date(date);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const fieldDate = localDate.toLocaleDateString('en-AU', options);
    // let optWithoutSecond = {hour: '2-digit', minute:'2-digit',hour12:true};
    const opt = { hour: '2-digit', minute: '2-digit', hour12: true };
    const time = localDate.toLocaleTimeString('en-AU');
    const resultTime =
      time === '12:00:00 am' ? '' : localDate.toLocaleTimeString('en-AU', opt).toString().toUpperCase();
    return `${fieldDate} ${resultTime}`;
  }

  return result;
}

export function formatStandardDate(date) {
  if (date === null) return '';
  if (moment(date).format('HH:mm:ss.SSS') !== '00:00:00.000') {
    return moment(date).format('DD/MM/YYYY h:mm A');
  }
  return moment(date).format('DD/MM/YYYY');
}
