export function decimalValue(e) {
  if ((e.key >= 0 && e.key <= 20) || e.key === '.') {
    const number = e.target.value + e.key;

    const regex = /^\d{1,14}?(\.\d{0,5})?$/;
    if (!regex.test(number) && number !== '') {
      e.preventDefault();
      e.stopPropagation();
    }
  } else {
    e.preventDefault();
    e.stopPropagation();
  }
}

export function formattedDecimalValue(e) {
  if ((e.key >= 0 && e.key <= 9) || e.key === '.') {
    const number = e.target.value + e.key;
    const arrayText = number.split('');

    if (arrayText.length) {
      const dotLength = arrayText.filter((item) => item === '.');
      if (dotLength.length > 1) {
        e.preventDefault();
        e.stopPropagation();
      }
    }

    const regex = /^(\d{1,14}|\.)?(\.\d{0,5})?$/;

    if (!regex.test(number) && number !== '') {
      e.preventDefault();
      e.stopPropagation();
    }
  } else {
    e.preventDefault();
    e.stopPropagation();
  }
}

export function decimalValueForQty(e) {
  if (e.key >= 0 && e.key <= 9) {
    const number = e.target.value + e.key;
    const arrayText = number.split('');
    if (arrayText.length) {
      const dotLength = arrayText.filter((item) => item === '.');
      if (dotLength.length > 1) {
        e.preventDefault();
        e.stopPropagation();
      }
    }

    const regex = /^(\d{1,9}|\.)?(\.\d{0,3})?$/;
    if (!regex.test(number) && number !== '') {
      e.preventDefault();
      e.stopPropagation();
    }
  } else {
    e.preventDefault();
    e.stopPropagation();
  }
}

export function protectionValue(e) {
  if (e.key >= 0 && e.key <= 8) {
    const regex = /[0-8]/;
    //   let regex = /^(\d[1,9]|\.)?(\.\d{0,3})?$/;

    if (!regex.test(e.key) && e.key !== '') {
      e.preventDefault();
      e.stopPropagation();
    }
  } else {
    e.preventDefault();
    e.stopPropagation();
  }
}

export function onCustomerCodeClick(e) {
  const text = e.target.value + e.key;

  const regex = /[a-zA-Z0-9]+$/;

  if (!regex.test(text) && text !== '') {
    e.preventDefault();
    e.stopPropagation();
  }
}

export function checkSpecialChar(e) {
  // if(e.currentTarget){
  //     const val = e.currentTarget.value;

  //     let reg = /[a-zA-Z0-9!@#$%^&*()_+_ ;:'",.<>?]+$/i.test(val)
  //     if(reg){
  //         let newVal = val.replace(/[^\w\s!@#$%^&*()_+_ ;:'",.<>?]/gi, '')
  //         if(newVal){
  //             return e.currentTarget.value = newVal
  //         }
  //     }else{
  //         return e.currentTarget.value = ""
  //     }

  // }

  return e.currentTarget.value;
}

export const onClickChange = (e) => {
  // let checkChar = checkSpecialChar(e)
  // if(!checkChar) return
  // const text = e.currentTarget.value
  // let regularExp = /[\x22\x3b\x5e\r\n?]/g;
  // e.currentTarget.value = text.match(regularExp) ? text.replace(regularExp, "") : text;
};
