import * as global from '../constants/global';
import * as endPoint from '../constants/endpoint';
import { APIHelper, SessionHelper, StorageHelper } from '../helpers';
import { logout } from '../helpers/externalLoginHelper';

const { storageName } = global;

class Authentication {
  authenticationHandler = (data) => {
    const result = {};
    return APIHelper.postRequest(endPoint.user.post, data)
      .then((res) => {
        if (res) {
          if (res.error) {
            result.isSuccess = false;
            result.message = res.error.status_code === 401 ? 'Invalid username or password' : res.error.message;

            return result;
          }

          result.isSuccess = true;
          Authentication.setAuthenticate(res);
        } else {
          result.isSuccess = false;
          result.message = res.data.error.message;
        }

        return result;
      })
      .catch((error) => {
        result.isSuccess = false;
        result.message = error;

        return result;
      });
  };

  static isAuthenticated = () => {
    return SessionHelper.isAuthenticated();
  };

  static setAuthenticate = (userDetails) => {
    if (userDetails == null) {
      const keysToRemove = [storageName.user, storageName.activeCompany, 'end'];

      keysToRemove.forEach((i) => {
        StorageHelper.deleteStorage(i);
      });

      return;
    }

    SessionHelper.authenticate(userDetails);
  };

  static getUser = () => {
    return SessionHelper.getUserSession();
  };

  static getToken = () => {
    const userSession = SessionHelper.getUserSession();
    const { session_token: token } = userSession;
    return token;
  };

  static getCompanyCode = () => {
    const user = Authentication.getUser();
    if (!user) return false;
    return user.activeCompany;
  };

  static eraseAllLocalData = () => {
    const localStorageKeys = [
      storageName.salesOrderFilter,
      storageName.salesOrderColumn,
      storageName.returnsFilter,
      storageName.returnsColumn,
      storageName.inboundFilter,
      storageName.inboundColumn,
      storageName.inboundProductColumn,
      storageName.inventoryFilter,
      storageName.inventoryColumn,
      storageName.inventoryMovementColumn,
      storageName.importFileNameSalesOrder,
      storageName.importedSalesOrder,
      storageName.importFileNameInbound,
      storageName.importedInbound,
      storageName.importFileNameInventory,
      storageName.importedInventory,
    ];

    localStorageKeys.forEach((element) => {
      StorageHelper.deleteStorage(element);
    });
  };

  static signOut = () => {
    SessionHelper.signOut();
    Authentication.eraseAllLocalData();
    Authentication.setAuthenticate(null);

    logout();
  };
}

export default Authentication;
