import { v4 as uuid } from 'uuid';
import { SettingConstant } from '../constants/settings';
import { getAllTokenData } from './tokenHelper';
import { StorageHelper } from 'helpers';

// GENERATING CODE VERIFIER
function dec2hex(dec) {
  return ('0' + dec.toString(16)).substr(-2);
}

function generateCodeVerifier() {
  var array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join('');
}

// GENERATING CODE CHALLENGE FROM VERIFIER
function sha256(plain) {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

function base64urlencode(a) {
  var str = '';
  var bytes = new Uint8Array(a);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

async function generateCodeChallengeFromVerifier(v) {
  var hashed = await sha256(v);
  var base64encoded = base64urlencode(hashed);
  return base64encoded;
}

async function loginWithAzureB2C() {
  const redirectURI = encodeURIComponent(`${window.location.origin}`);
  const state = uuid();
  const tenant = SettingConstant.TENANT;
  const clientId = SettingConstant.CLIENT_ID;
  const scope = `${clientId}%20offline_access`;
  const codeVerifier = generateCodeVerifier();
  const code_challenge = await generateCodeChallengeFromVerifier(codeVerifier);
  const url = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${SettingConstant.POLICY}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectURI}&response_mode=query&scope=${scope}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=S256`;

  StorageHelper.setStorage(SettingConstant.CODE_VERIFIER, codeVerifier);
  location.href = url;
}

async function logout() {
  StorageHelper.deleteStorage('TOKEN_DATA');

  const redirectURI = encodeURIComponent(`${window.location.origin}`);
  const tenant = SettingConstant.TENANT;
  const url = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${SettingConstant.POLICY}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirectURI}`;

  location.href = url;
}

async function getAccessToken(code) {
  const tenant = SettingConstant.TENANT;
  const clientId = SettingConstant.CLIENT_ID;
  const code_verifier = StorageHelper.getStorage(SettingConstant.CODE_VERIFIER);
  const clientSecret = StorageHelper.getStorage(SettingConstant.CLIENT_SECRET);

  let url = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${SettingConstant.POLICY}/oauth2/v2.0/token?grant_type=authorization_code`;
  url += `&client_id=${clientId}`;
  url += `&client_secret=${clientSecret}`;
  url += '&code=' + code;
  url += '&redirect_uri=' + encodeURIComponent(`${window.location.origin}`);
  url += '&code_verifier=' + code_verifier;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    StorageHelper.setStorage(SettingConstant.TOKEN_DATA, JSON.stringify(await response.json()));
    location.href = window.location.origin;
  } catch (e) {
    console.log('Failed to get the access token !', e);
  }
}

async function refreshToken() {
  const tokenData = getAllTokenData();
  const refresh_token = tokenData ? tokenData.refresh_token : '';

  const tenant = SettingConstant.TENANT;
  const clientId = SettingConstant.CLIENT_ID;
  const clientSecret = StorageHelper.getStorage(SettingConstant.CLIENT_SECRET);
  let url = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${SettingConstant.POLICY}/oauth2/v2.0/token?grant_type=refresh_token`;
  url += `&client_id=${clientId}`;
  url += `&client_secret=${clientSecret}`;
  url += '&refresh_token=' + refresh_token;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    StorageHelper.setStorage(SettingConstant.TOKEN_DATA, JSON.stringify(await response.json()));
  } catch (e) {
    console.log('Failed to get the access token !', e);
  }
}

export {
  generateCodeVerifier,
  generateCodeChallengeFromVerifier,
  loginWithAzureB2C,
  logout,
  getAccessToken,
  refreshToken,
};
