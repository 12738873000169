import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer from '../modules';

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const composedMiddlewares = applyMiddleware(...middlewares);

  const storeEnhancers = composeWithDevTools({
    name: 'Express eStore Logistics',
  })(composedMiddlewares);

  const persistConfig = {
    key: 'express-estore-logistics:root',
    storage,
    // blacklist: ['product', 'filter'],
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(persistedReducer, /* preloadedState, */ storeEnhancers);
  const persistor = persistStore(store);

  return { store, persistor };
}
