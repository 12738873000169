import * as constants from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  criteria: [],
  search: '%',
  isArchivalSearch: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FILTER_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case constants.FILTER_IS_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case constants.FILTER_SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case constants.FILTER_IS_FETCHING_DATA:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: '',
        criteria: [],
      };
    case constants.FILTER_SET_CRITERIA_DATA:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: '',
        criteria: action.payload,
      };
    case constants.FILTER_SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case constants.FILTER_CLEAR:
      return {
        ...state,
        criteria: [],
        search: '%',
      };
    case constants.FILTER_IS_ARCHIVAL_SEARCH:
      return {
        ...state,
        isArchivalSearch: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
