import * as constants from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  data: [],
  fullData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.PRODUCT_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case constants.PRODUCT_IS_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case constants.PRODUCT_SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case constants.PRODUCT_IS_FETCHING_DATA:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: '',
        data: [],
      };
    case constants.PRODUCT_SET_DATA:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: '',
        data: action.payload.map((prod) => prod.item),
        fullData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
