export function twoDecimalPlace(string) {
  if (string === null) return '';

  if (string && string !== '') {
    const newFloat = parseFloat(string);
    return newFloat.toString().match(/^-?\d+(?:\.\d{0,2})?/);
    // return (newFloat.toFixed(2));
    // return +(Math.round(string + "e+2")  + "e-2");
  }
  return '';
}

export function formatPrice(string) {
  if (string == null) return '';

  if (string) {
    // return +(Math.round(string + "e+2") + "e-2");
    const newFloat = parseFloat(string).toFixed(2);
    return newFloat.toString().match(/^-?\d+(?:\.\d{0,2})?/);
  }

  return '';
}

export function displayNumberValue(source) {
  const result = parseInt(source, 10) ? parseInt(source, 10) : 0;
  return result;
}

export function parseNumber(val) {
  try {
    if (typeof val === 'number') return val;

    return Number(val.replace(/,/g, ''));
  } catch {
    return 0;
  }
}
