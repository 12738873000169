import * as constants from './constants';

import * as endPoint from '../../constants/endpoint';
import { APIHelper } from '../../helpers';

export const setIsLoading = (isLoading) => {
  return {
    type: constants.FILTER_IS_LOADING,
    payload: isLoading,
  };
};

export const setIsError = (isError) => {
  return {
    type: constants.FILTER_IS_ERROR,
    payload: isError,
  };
};

export const setErrorMessage = (message) => {
  return {
    type: constants.FILTER_SET_ERROR_MESSAGE,
    payload: message,
  };
};

export const setOnFetchingData = () => {
  return {
    type: constants.FILTER_IS_FETCHING_DATA,
  };
};

export const setFilterCriteriaData = (data) => {
  return {
    type: constants.FILTER_SET_CRITERIA_DATA,
    payload: data,
  };
};

export const setSearch = (search) => {
  return {
    type: constants.FILTER_SET_SEARCH,
    payload: search,
  };
};

export const setIsArchivalSearch = (val) => {
  return {
    type: constants.FILTER_IS_ARCHIVAL_SEARCH,
    payload: val,
  };
};

export const clearAllFilter = () => {
  return {
    type: constants.FILTER_CLEAR,
  };
};

export const getAllFilterCriteria = () => {
  return async (dispatch) => {
    dispatch(setOnFetchingData());
    try {
      const options = { params: { filter_name: '', v: new Date().getTime() } };
      const response = await APIHelper.getRequest(endPoint.filterCriteria.list, options);
      if (response) {
        dispatch(setFilterCriteriaData(response));
      } else {
        dispatch(setIsError(true));
        dispatch(setErrorMessage('There is no response.'));
      }
    } catch (error) {
      dispatch(setFilterCriteriaData([]));
      dispatch(setIsError(true));
      dispatch(setErrorMessage(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
